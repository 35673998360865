import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from "html-react-parser"

// Components
import SectionWrapper from "../SectionWrapper";
import CustomButton from "../CustomButton";
import {ImageModule} from '../../modules/Image_Module'
import RIghtSideSection from '../IntroSection/RIghtSideSection'


// Styles
import './CareerDetails.scss';
import '../../layout/DiscoverMarylebone/DirectorProfile/DirectorProfile.scss';


const CareerDetails = ( { details, ContactForm, myRef } ) => {

    return (
        <SectionWrapper className="director-profile-wrapper">
            <Container>
                <Row>
                    <Col
                        lg="8"
                    >
                        <div className="director-profile-header">
                            <h1 className="general-heading">
                                {details.Title}
                            </h1>
                            
                            {details.Location && 
                            <p className="speciality-areas">
                                <b>Location:</b> {details.Location}
                            </p>
                            }
  
                        </div>

                        {details.Content && 
                        <div className="director-profile-body">
                            {HTMLReactParser(details.Content)}
                        </div>
                        }

                        {details.Whats_In_It_For_You && 
                        <div className="director-profile-body">
                            <h2>What's in it for you?</h2>
                            {HTMLReactParser(details.Whats_In_It_For_You)}
                        </div>
                        }

                        <div ref={myRef}></div> 
         
                    </Col>
                    <Col>
                        <div className="director-image">
                            <RIghtSideSection Right_Side_Module={{Show_Contact_Details:true, Title:"If you would like to find out about  exciting opportunities with Resi.Land get in touch today"}} />

                            <div className="d-none d-sm-block mt-4">
                                <CustomButton
                                    variant="green"
                                    value={`Apply for this Job`}
                                    onClick={ContactForm}
                                />
                            </div> 
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </SectionWrapper>
    )
}

export default CareerDetails