import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"; 

// Styles
import './CharityStories.scss';

// Components

import CustomButton from "components/CustomButton";
import {ImageModule} from '../../modules/Image_Module'
import Modal_Form from '../../modules/modal_form'

import ContactCard from '../ContactCard'

import PlayVideoIcon from '../../images/play_video_icon.svg'

const CharityStories = ( props ) => {
    const location = useLocation();

    const [isOpen,setIsOpen] = useState(false);

    const data = useStaticQuery(graphql`
    query ContactDetails{
      glstrapi {
        globalModule {
          Contact_Details {
            Address
            Email_ID
            Phone_Number
            id
          }
        }

      }
    }`)

    let Contact_Details = data?.glstrapi?.globalModule?.Contact_Details;
    

    return (
    <>
        <div className="charity-stories-sidebar">
            {props.Right_Side_Image && 
                <div className="video-container">
                    <ImageModule ImageSrc={props.Right_Side_Image} classNames={"rightside_img"} />

                    {props.Embed_Video_URL && 
                        <span className="play_btn" onClick={()=>props.setPlayVideo(props.Embed_Video_URL)}><img src={PlayVideoIcon} alt="Play Video - Resiland" /></span>
                    }
                </div>
            }

            {props?.Team_Module && 
            <>            
                {props?.Team_Module.length > 0 && props?.Team_Module.map((item, index) => {

                    var last_item = index === (props?.Team_Module.length - 1) ? "last_item_cls" : "";
                    return(
                        <ContactCard item={item.team} last_item_cls={last_item} />
                    )
                })  
                }                
            </>
            }

            {props?.Right_Side_Module?.Show_Contact_Details && 
            <>            
                <h4>{props.Right_Side_Module.Title}</h4>
                <div className="contact-information">
                    <div className="location">
                        <div className="icon-image">
                            <img
                                src={ require( `../../images/location-icon.png` ).default }
                                width="auto"
                                height="auto"
                                alt="Location Icon"
                            />
                        </div>
                        <p className="mb-0">{Contact_Details?.Address}</p>
                    </div>
                    <div className="phone">
                        <div className="icon-image">
                            <img
                                src={ require( `../../images/phone-icon.png` ).default }
                                width="auto"
                                height="auto"
                                alt="Phone Icon"
                            />
                        </div>
                        <p className="mb-0"><a href={`tel:${Contact_Details?.Phone_Number}`}>{Contact_Details?.Phone_Number}</a></p>
                    </div>
                    {!props?.Right_Side_Module?.Show_Call_Back_Button &&  
                        <div className="email_icon">
                            <div className="icon-image">
                                <img
                                    src={ require( `../../images/mail-icon.svg` ).default }
                                    width="auto"
                                    height="auto"
                                    alt="EMail Icon"
                                />
                            </div>
                            <p className="mb-0"><a href={`mailto:${Contact_Details?.Email_ID}`}>{Contact_Details?.Email_ID}</a></p>
                        </div>
                    }
                </div>
            </>
            }

            {props?.Right_Side_Module?.Show_Call_Back_Button && 
                <div className="hide-on-tab-and-mobile">
                    <CustomButton
                        variant="green"
                        value="REQUEST A CALLBACK"
                        onClick={()=>setIsOpen(true)}
                    />
                </div>
            }
        </div>


        {isOpen && <Modal_Form isOpen={isOpen} setIsOpen={setIsOpen} location={location} form_type={"request_call_back"}/>}
    </>   
    )
}

export default CharityStories

