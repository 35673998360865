import React, { useState, useEffect } from "react"

// Temporary Data
import newsData from "../data/news-details.json"

// Components
import {CustomLinks} from "../components/common/menuUtils"
import LoaderLogo from "../components/LoaderLogo"

import Layout from "../components/layout"
import BreadCrumbs from "../components/BreadCrumbs/StaticBreadcrumb"
import CareerDetails from "../components/CareerDetails"
import FormSection from "../components/FormsSection"

import {CareersDetails} from "../queries/common_use_query";

const CareerDetailsTemplate = (props) => {
    const { loading, error, data } = CareersDetails(props.pageContext?.slug);
    const [ details, setGetDetails ] = useState({});

    const myRef = React.createRef();

    const ContactForm = () =>{
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    useEffect(()=>{
        data && data.careers.length > 0 && setGetDetails(data.careers[0])
    },[data])
    
    // Loading logo 
    if (loading) return (
        <LoaderLogo />
    );
    // Loading logo

    //console.log("data", details)

    return (
        <React.Fragment>
            {details && details.Title &&
                <Layout template="property_details" Meta_Title={details.Title} Meta_Description={details.Title}>
                    <BreadCrumbs
                        breadCrumbsData={ 
                            [{
                                name: 'Home',
                                link: '/'
                            },
                            {
                                name: "About Us",
                                link: "/"+CustomLinks.about+"/"
                            },
                            {
                                name: "Careers",
                                link: "/"+CustomLinks.careers+"/"
                            },
                            {
                                name: details.Title,
                                // link: '#'
                            }]
                        }
                    />
                    <CareerDetails details={details} ContactForm={ContactForm} myRef={myRef} />  
   
                    <FormSection Module={{Title:`Apply for this job`, Form_Type:"Career_Details", job_title:details.Title}}  />
                    
                </Layout>
            }
            
        </React.Fragment>
    )
}

export default CareerDetailsTemplate